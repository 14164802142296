import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const PrintPDF = (props) => {
    const [printType, setPrintType] = useState('');
    const printableDivRef = useRef(null);

    const history = useHistory();

    const {
        questionsListArr
    } = props.location.state;

   
    const handlePrint = (type) => {
        setPrintType(type);
        setTimeout(() => {
            
            // Trigger print
            window.print();
        
        }, 0);
    };


    const { t } = useTranslation();

    const createMarkup = (value) => {
        return { __html: value };
    };

    var anssArr = [];

    const colorList = ["#3cc1b9", "#C15C3C", "#3C99C1", "#E1B929"];

    return (
        <div className="row no-gutters">
            <div className="col-md-12 pb-4 ">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',  // Ensures items are spaced out
                        alignItems: 'center',  // Centers items vertically
                        padding: '1rem',
                    }}
                >
                    <button onClick={() => history.goBack()} className="quize_edit">
                        <icon class="icon-back-arrow"></icon>
                    </button>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '1rem',
                            gap: '1rem'
                        }}
                    >

                        <button
                            style={{
                                font: '1rem/normal Poppins',
                                color: '#FFF',
                                borderRadius: '5px',
                                padding: '0.5rem 0.5rem',
                                border: 'none',
                                backgroundColor: '#F16B24'
                            }}
                            onClick={() => handlePrint('all')}
                        >
                            Print
                        </button>
                        <button
                            style={{
                                font: '1rem/normal Poppins',
                                color: '#FFF',
                                borderRadius: '5px',
                                padding: '0.5rem 0.5rem',
                                border: 'none',
                                backgroundColor: '#F16B24'
                            }}
                            onClick={() => handlePrint('questions')}
                        >
                            Print Questions
                        </button>
                    </div>
                </div>
                <div ref={printableDivRef} className='printableDiv'>
                    {(questionsListArr.map((ques, index) => (
                        <div
                            className='questionCardContainer'
                            style={{ borderBottom: "8px solid #f1f4f6", marginLeft: "" }}>
                            <span style={{ color: '#FC8643', fontWeight: 700 }}>{ques.type.toUpperCase()}</span>
                            <div className='question_Button' key={ques._id}>
                                <div className="index_ques">
                                    {`${index + 1}`}.&nbsp;
                                    {ques.image ?
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                width: "100%",
                                            }}
                                        >
                                            <div
                                                className="qest-text"
                                                dangerouslySetInnerHTML={createMarkup(ques.questionText)}
                                            />
                                            <img src={ques.image} alt="" style={{ maxHeight: '250px', objectFit: 'contain' }} />
                                        </div>
                                        :
                                        ques.additional_info.has_latex === "Y" ?
                                            <div className="qest-text" dangerouslySetInnerHTML={createMarkup(ques.questionText)} />
                                            :
                                            <div className="qest-text" dangerouslySetInnerHTML={createMarkup(ques.questionText)} />
                                    }
                                </div>

                                <div className="buttons">
                                    <div className="quet-selected">
                                        <ul>

                                            <li className="qust-radio bkg-gry">
                                                <span>{ques.q_question_marks?.positive}</span>
                                                <span> {ques.q_question_marks?.negative}</span>
                                            </li>
                                            <li className="qust-second bkg-gry">

                                                <span style={{ fontSize: "14px" }}> {ques.q_question_duration} S</span>

                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className='mb-2 hrLine' />

                            {(printType === 'all' || printType === '') && <div
                                style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                            >
                                {ques.type === "mcq" ?
                                    <>
                                        {ques.data.options.map((el, i) => {
                                            return (
                                                <>
                                                    {el.option_type === "image" ?
                                                        <div
                                                            className="singleOption"
                                                            style={{ display: 'flex', alignItems: 'center' }}
                                                        >
                                                            <div style={{ color: '#2B2B2B' }}>{el.option_name}.</div>
                                                            <img src={el.option_image} alt="" style={{ maxHeight: '250px', objectFit: 'contain' }} />
                                                        </div>
                                                        :
                                                        <div
                                                            className="singleOption"
                                                            style={
                                                                el.option_name === ques.answer?.option_name ? { backgroundColor: '#C8FCBF', color: '#124609' } : { backgroundColor: 'transparent', color: '#999898' }
                                                            }
                                                        >
                                                            <div style={{ color: '#2B2B2B' }}>{el.option_name}.</div>
                                                            <div
                                                                className="qest-text"
                                                                dangerouslySetInnerHTML={createMarkup(el.option_value)}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })}
                                    </>
                                    :
                                    <>  {ques.type === "subjective" ? <>
                                        <div
                                            className="singleOption"
                                            style={{ backgroundColor: '#C8FCBF', color: '#124609' }}
                                        >
                                            <div style={{ color: '#2B2B2B' }}>{t("assessment.ans")}</div>

                                            {ques.sol_image ? (<div
                                            >
                                                <img src={ques.sol_image} alt="" style={{ maxHeight: '250px', objectFit: 'contain' }} />
                                            </div>) : (<div
                                                dangerouslySetInnerHTML={createMarkup(
                                                    ques.answer
                                                )}
                                            />)}
                                        </div>
                                    </> :
                                        <>
                                            {ques.type === "mcms" ?
                                                <>
                                                    {ques.data.options.map((el, i) => {
                                                        return (
                                                            <>
                                                                {el.option_type === "image" ?
                                                                    <div
                                                                        className="singleOption"
                                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                                    >
                                                                        <div style={{ color: '#2B2B2B' }}>{el.option_name}.</div>
                                                                        <img src={el.option_image} alt='' style={{ maxHeight: '250px', objectFit: 'contain' }} />
                                                                    </div> :
                                                                    <div className="singleOption" style={anssArr.includes(el.option_name) ? { backgroundColor: '#C8FCBF', color: '#124609' } : { backgroundColor: 'transparent', color: '#999898' }}>
                                                                        <div style={{ color: '#2B2B2B' }}>{el.option_name}.</div>
                                                                        <div
                                                                            className="qest-text"
                                                                            dangerouslySetInnerHTML={createMarkup(
                                                                                el.option_value
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </> :
                                                <div className="optionBox" style={{ backgroundColor: colorList[Math.floor(Math.random() * 4)] }} >
                                                    <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "center", "width": "100%", "height": "100%", "marginBottom": "15px", "padding": "10px", "flexWrap": "wrap", margiTop: "30px" }}
                                                        dangerouslySetInnerHTML={createMarkup(ques.answer.option_value)}
                                                    />
                                                </div>
                                            }
                                        </>
                                    }
                                    </>
                                }

                            </div>}
                        </div>
                    )))}
                </div>
            </div>
        </div>
    )
}

export default PrintPDF